@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 10;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;

}
  .mode-toggle {
    /* margin-right: auto; */
    margin-left: 10%;
  }
  .mode-toggle img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  


.navbar h1 {
  color: #f1356d;
}

.navbar .links {
  justify-content: space-between;
  overflow-x: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  display: inline-block
}

.navbar a:hover {
  color: #f1356d;
}

.navbar a.active {
color: #4b4849;
background: #f0f0f0;
rext-decoration: none;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.footer {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  font-size: 10px
}

button {
  padding: 10px;
  font-size: 16px;
  flex-grow: 1;
}

input {
  padding: 10px;
  font-size: 16px;
  flex-grow: 1;
}
.cords-form,
.search-list-nav {
  max-width: 480px;
  margin: 30px auto;
}
.search-list-nav ol {
  padding: 0;
}
.search-list-nav li {
  border: 2px solid #e4e4e4;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 4px 4px 6px rgba(0,0,0,0.05);
  border-radius: 4px;
  list-style-type: none;
  margin: 20px 0;
}

.search .filters-nav {
  display: flex;
  gap: 10px;
}
.search-list button {
  padding: 10px;
  font-size: 16px;
  flex-grow: 1;
}


.bigger-size-letter {
font-weight: bold;
}

img {
/*max-width: 360px;*/
}
/* dark mode */

 .dark{
color: #999;

}

.dark .navbar a {
  color: #fff;
}

.dark.navbar.links {
color: 'white'
}

.dark button {
  background: #555555;
  color: #E4E4E4;
}

.dark.navbar a:hover {
  color: #f1356d;
}

.dark.navbar a.active {
color: #E4E4E4;
background: #778899;
}

.dark.footer a,
.dark.footer span {
color: #E4E4E4;
}


.dark .page-title,
.dark .loading,
.dark .error,
.dark label,
.dark div,
.dark span,
.dark h2,
.dark p {
  color: #e4e4e4;
}

